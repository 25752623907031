import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MASTER_SERVER_ENDPOINTS, CRYPTOJS_SECRET} from '../utils/constants';
import { StorageService } from './storage.service';
import { UserProfileResponse } from '../pages/profile/profile.page';
import { BecomeCreatorRequestAttributes, SocialMedia } from '@qhot-mx/qhot-database-dto-interfaces';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserCollectionsResponse } from '../components/collection-button/collection-button.component';
import { ResponseDonationPageModule } from '../pages/notifications/response-donation/response-donation.module';
import * as CryptoJS from 'crypto-js';


const serverBaseUrl = MASTER_SERVER_ENDPOINTS.serverBaseUrl;
const baseApiPath = MASTER_SERVER_ENDPOINTS.basePath;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }

  public async getBasicProfile(userParam: string | number) {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.basicProfile;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;
    /* eslint-disable @typescript-eslint/naming-convention */
    let httpOpt = {};
    if(typeof userParam === 'number'){
     httpOpt = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        params: new HttpParams()
          .append('userId', userParam),
      };
    }else if(typeof userParam === 'string'){
      httpOpt = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        params: new HttpParams()
          .append('username', userParam),
      };
    }
    const httpOptions = httpOpt;
    /* eslint-enable @typescript-eslint/naming-convention */
    try {
      console.log(`[user-service][getUserProfile] Querying to "${queryUrl}"...`);
      const userProfile = await this.http.get<UserProfileResponse>(queryUrl, httpOptions).toPromise();
      return userProfile;
    } catch (e) {
      console.error('[user-service][getUserProfile] Error while getting profile', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getUserProfile(userParam: string | number) {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.profile;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;
    /* eslint-disable @typescript-eslint/naming-convention */
    let httpOpt ={};
    if(typeof userParam === 'number'){
      httpOpt = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        params: new HttpParams()
          .append('userId', userParam),
      };
    }else if(typeof userParam === 'string'){
       httpOpt = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await this.storageService.getJwt()}`,
        }),
        params: new HttpParams()
          .append('username', userParam),
      };
    }
    const httpOptions = httpOpt;
    /* eslint-enable @typescript-eslint/naming-convention */
    try {
      console.log(`[user-service][getUserProfile] Querying to "${queryUrl}"...`);
      const userProfile = await this.http.get<UserProfileResponse>(queryUrl, httpOptions).toPromise();
      return userProfile;
    } catch (e) {
      console.error('[user-service][getUserProfile] Error while getting profile', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getSubscriptions() {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.subscriptions;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    try {
      console.log(`[user-service][getSubscriptions] Querying to "${queryUrl}"...`);
      const response = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][getSubscriptions] Error while getting subscriptions', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getAllCollectionsByUser(userId: number) {
    const endpoint = MASTER_SERVER_ENDPOINTS.collections.allCollectionsByUser;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}/${userId}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    try {
      console.log(`[user-service][getCollections] Querying to "${queryUrl}"...`);
      const response: any = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][getCollections] Error while getting collections', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getCollectionsByPublicationId(publicationId: number) {
    const endpoint = MASTER_SERVER_ENDPOINTS.collections.allCollectionsByPublicationId;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}/${publicationId}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    try {
      console.log(`[user-service][getCollectionsByPublicationId] Querying to "${queryUrl}"...`);
      const response: any = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][getCollectionsByPublicationId] Error while getting collections', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }
  private collectionCreatedSource = new Subject<void>();
  collectionCreated$ = this.collectionCreatedSource.asObservable();

  public async createCollection(userId: number, name: string): Promise<void> {
    const endpoint = MASTER_SERVER_ENDPOINTS.collections.createCollection;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    const body = JSON.stringify({ userId, name });

    try {
      console.log(`[user-service][createCollections] Querying to "${queryUrl}"...`);
      const response: any = await this.http.post(queryUrl, body, httpOptions).toPromise();
      this.collectionCreatedSource.next();
      return response;
    } catch (e) {
      console.error('[user-service][createCollections] Error while creating collection', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async updateCollection(collectionId: number, name: string) {
    const endpoint = MASTER_SERVER_ENDPOINTS.collections.updateCollection;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}/${collectionId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    const body = JSON.stringify({ collectionId, name });

    try {
      console.log(`[user-service][updateCollection] Querying to "${queryUrl}"...`);
      const response = await this.http.put(queryUrl, body, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][updateCollections] Error while updating collection', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }
  private collectionDeletedSource = new Subject<void>();
  collectionDeleted$ = this.collectionDeletedSource.asObservable();
  public async deleteCollection(userId: number, collectionId: number) {
    const endpoint = MASTER_SERVER_ENDPOINTS.collections.deleteCollection;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;
    const body = JSON.stringify({ userId, collectionId });
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      body
    };

    try {
      console.log(`[user-service][deleteCollection] Querying to "${queryUrl}"...`);
      //const response = await this.http.post(queryUrl, {}, httpOptions).toPromise();
      const response = await this.http.delete(queryUrl, httpOptions).toPromise();
      this.collectionDeletedSource.next();
      return response;
    } catch (e) {
      console.error('[user-service][deleteCollections] Error while deleting collection', e);
      throw new Error((e as HttpErrorResponse).message);
    }

  }


  public async addToCollection(userId: number, collectionId: number, publicationId: string) {
    const endpoint = MASTER_SERVER_ENDPOINTS.collections.addToCollection;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}/`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };




    const body = JSON.stringify({ userId, collectionId, publicationId });

    try {
      console.log(`[user-service][addToCollection] Querying to "${queryUrl}"...`);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][addToCollection] Error while adding to collection', e);
      throw new Error((e as HttpErrorResponse).message);

    }
  }

  public async removeFromCollection(collectionPublicationId: number) {
    const endpoint = MASTER_SERVER_ENDPOINTS.collections.removeFromCollection;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;
    const body = JSON.stringify({ collectionPublicationId });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      body
    };



    try {
      console.log(`[user-service][addToCollection] Querying to "${queryUrl}"...`);
      const response = await this.http.delete(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][removeFromCollection] Error while removing from collection', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async removeFromCollectionFeed(Collection_collectionId: number, Publication_publicationId: any) {
    const endpoint = MASTER_SERVER_ENDPOINTS.collections.removeFromCollectionFeed;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;
    const body = JSON.stringify({ Collection_collectionId, Publication_publicationId });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      body
    };



    try {
      console.log(`[user-service][removeFromCollectionFeed] Querying to "${queryUrl}"...`);
      const response = await this.http.delete(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][removeFromCollectionFeed] Error while removing from collection', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getCollectionImage(id: number, pageSize: number, lastPublicationId: number) {
    const endpoint = MASTER_SERVER_ENDPOINTS.collections.getCollectionImages;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}/${id}/images`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams({
        fromObject: {
          pageSize: pageSize.toString(),
          lastPublicationId: lastPublicationId.toString(),
        },
      }),
    };
    try {
      console.log(`[user-service][getCollectionsImages] Querying to "${queryUrl}"...`);
      const response: any = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][getCollectionsImages] Error while getting collections images', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }
  public async getCollectionVideo(id: number, pageSize: number, lastPublicationId: number) {
    const endpoint = MASTER_SERVER_ENDPOINTS.collections.getCollectionVideos;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}/${id}/videos`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams({
        fromObject: {
          pageSize: pageSize.toString(),
          lastPublicationId: lastPublicationId.toString(),
        },
      }),
    };
    try {
      console.log(`[user-service][getCollectionsVideos] Querying to "${queryUrl}"...`);
      const response: any = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][getCollectionsVideos] Error while getting collections videos', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }


  public async updateProfile(params) {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.profile;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    const body = JSON.stringify(params);
    try {
      console.log(`[user-service][updateProfile] Querying to "${queryUrl}"...`);
      const userProfile = await this.http.put(queryUrl, body, httpOptions).toPromise();
      return userProfile;
    } catch (e) {
      console.error('[user-service][updateProfile] Error while getting profile', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async newBecomeCreatorRequest(form: any) {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.becomeCreatorRequest;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      observe: 'response' as const,
    };
    const body = JSON.stringify(form);

    try {
      console.log(`[userservice][newBecomeCreatorRequest] Querying to ${queryUrl}... with body ${body}`);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      console.log(`[userservice][newBecomeCreatorRequest] Response from server: ${response.status} ${response.statusText}`);
      return response;
    } catch (e) {
      console.error('[userservice][newBecomeCreatorRequest] Error while registering user', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async rejectBecomeCreatorRequest(id: number) {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.rejectBecomeCreatorRequest;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}/${id}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      observe: 'response' as const,
    };
    const body = {id};

    try {
      console.log(`[userservice][rejectBecomeCreatorRequest] Querying to ${queryUrl}... with body ${body}`);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      console.log(`[userservice][rejectBecomeCreatorRequest] Response from server: ${response.status} ${response.statusText}`);
      return response;
    } catch (e) {
      console.error('[userservice][rejectBecomeCreatorRequest] Error while registering user', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public becomeCreatorUploadMedia(form: FormData, requestId: number, jwt: string): Observable<any> {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.becomeCreatorUploadMedia;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}/${requestId}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        // 'Content-Type':  'multipart/form-data',
        Authorization: `Bearer ${jwt}`,
      }),
      reportProgress: true,
      observe: 'response' as const,
    };
    const body = form;

    console.log(`[userservice][newBecomeCreatorRequest] Querying to ${queryUrl}... with body ${body}`);
    return this.http.post(queryUrl, body, httpOptions).pipe(catchError(this.errorMgmt));

  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(() => errorMessage);
  }

  public async setPassword(oldPassword: string, newPassword: string) {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.setPassword;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;
    console.log(await this.storageService.getJwt());

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
    };
    oldPassword = CryptoJS.AES.encrypt(oldPassword, CRYPTOJS_SECRET.secretKey).toString();
    newPassword = CryptoJS.AES.encrypt(newPassword,CRYPTOJS_SECRET.secretKey).toString();

    const body = JSON.stringify({ oldPassword, newPassword });
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[user-service][getUserProfile] Querying to "${queryUrl}"...`);
      // const response = await this.http.put(queryUrl, body, httpOptions).toPromise() as AuthResponse;
      const userProfile = await this.http.put<UserProfileResponse>(queryUrl, body, httpOptions).toPromise();
      return userProfile;
    } catch (e) {
      console.error('[user-service][getUserProfile] Error while getting creator', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getNotificationPreferences(userId: number) {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.notificationPreferences;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('userId', userId),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[user-service][getUserProfile] Querying to "${queryUrl}"...`);
      const userProfile = await this.http.get<UserProfileResponse>(queryUrl, httpOptions).toPromise();
      return userProfile;
    } catch (e) {
      console.error('[user-service][getUserProfile] Error while getting creator', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async setNotificationPreferences(userId: number, notificationPreferences: []) {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.notificationPreferences;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('userId', userId),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    const body = JSON.stringify(notificationPreferences);
    try {
      console.log(`[user-service][getUserProfile] Querying to "${queryUrl}"...`);
      const userProfile = await this.http.put(queryUrl, body, httpOptions).toPromise();
      return userProfile;
    } catch (e) {
      console.error('[user-service][getUserProfile] Error while getting creator', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }



  public async getAreasOfInterestUser(userId: number) {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.areasOfInterestUser;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      params: new HttpParams()
        .append('userId', userId),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[user-service][getUserProfile] Querying to "${queryUrl}"...`);
      const userProfile = await this.http.get<UserProfileResponse>(queryUrl, httpOptions).toPromise();
      return userProfile;
    } catch (e) {
      console.error('[user-service][getUserProfile] Error while getting creator', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getSocialMediaCatalog() {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.getSocialMediaCatalog;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[user-service][getSocialMediaCatalog] Querying to "${queryUrl}"...`);
      const response = await this.http.get<SocialMedia[]>(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][getSocialMediaCatalog] Error while getting social media catalog', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async uploadMediaProfilePicture(form: FormData,) {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.createUserProfileMedia;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        // 'Content-Type':  'multipart/form-data',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
      observe: 'response' as const,
      params: new HttpParams()
        .append('profileMediaType', 'profile_picture'),
    };
    const body = form;

    try {
      console.log(`[userservice][uploadMediaProfile] Querying to ${queryUrl}... with body ${body}`);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      console.log(`[userservice][uploadMediaProfile] Response from server: ${response.status} ${response.statusText}`);
      return response;
    } catch (e) {
      console.error('[userservice][uploadMediaProfile] Error while uploading media', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getRandomPhrase() {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.getRandomPhrase;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[user-service][getRandomPhrase] Querying to "${queryUrl}"...`);
      const response = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][getRandomPhrase] Error while getting a random phrase', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }


  public async deleteAccount() {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.deleteAccount;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[user-service][deleteAccount] Querying to "${queryUrl}"...`);
      const response = await this.http.post(queryUrl, {}, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][deleteAccount] Error while deleting account', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async setSocialMedia(params) {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.setSocialMedia;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      }),
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    const body = JSON.stringify(params);
    try {
      console.log(`[user-service][setSocialMedia] Querying to "${queryUrl}"...`);
      const userProfile = await this.http.post(queryUrl, body, httpOptions).toPromise();
      return userProfile;
    } catch (e) {
      console.error('[user-service][setSocialMedia] Error while setting social media', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getUserIsBlocked(creatorId) {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.getUserIsBlocked;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}/?creatorId=${creatorId}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    try {
      console.log(`[user-service][getUserIsBlocked] Querying to "${queryUrl}"...`);
      const response = await this.http.get(queryUrl, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][getUserIsBlocked] Error while getting a blocked user info', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async getUserData() {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.getUserData;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    try {
      console.log(`[user-service][getUserData] Querying to "${queryUrl}"...`);
      const userProfile = await this.http.get(queryUrl, httpOptions).toPromise();
      return userProfile;
    } catch (e) {
      console.error('[user-service][getUserData] Error while getting profile', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async saveUserData(params) {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.saveUserData;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };

    const body = JSON.stringify(params);
    try {
      console.log(`[user-service][saveUserData] Querying to "${queryUrl}"...`);
      const userProfile = await this.http.put(queryUrl, body, httpOptions).toPromise();
      return userProfile;
    } catch (e) {
      console.error('[user-service][saveUserData] Error while update profile', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }

  public async updateUserMedia(form: FormData, requestId: number): Promise<Observable<any>> {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.updateOfficialId;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}/${requestId}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        // 'Content-Type':  'multipart/form-data',
        // Authorization: `Bearer ${jwt}`,
         Authorization: `Bearer ${await this.storageService.getJwt()}`
      }),
      reportProgress: true,
      observe: 'response' as const,
    };
    const body = form;

    console.log(`[userservice][updateUserMedia] Querying to ${queryUrl}... with body ${body}`);
    return this.http.post(queryUrl, body, httpOptions).pipe(catchError(this.errorMgmt));

  }

  public async sendFailureReport(reportObj: any) {
    const endpoint = MASTER_SERVER_ENDPOINTS.user.reportFailure;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.storageService.getJwt()}`,
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    const body = JSON.stringify(reportObj);
    try {
      console.log(`[user-service][sendFailureReport] Querying to "${queryUrl}"...`);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][sendFailureReport] Error while sending failure report', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }
  public async sendSupportReport(reportObj: any) {
    const endpoint = MASTER_SERVER_ENDPOINTS.support.support;
    const queryUrl = `${serverBaseUrl}/${baseApiPath}/${endpoint}`;

    /* eslint-disable @typescript-eslint/naming-convention */
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    /* eslint-enable @typescript-eslint/naming-convention */

    const body = JSON.stringify(reportObj);
    try {
      console.log(`[user-service][sendSupportReport] Querying to "${queryUrl}"...`);
      const response = await this.http.post(queryUrl, body, httpOptions).toPromise();
      return response;
    } catch (e) {
      console.error('[user-service][sendSupportReport] Error while sending support report', e);
      throw new Error((e as HttpErrorResponse).message);
    }
  }



}
