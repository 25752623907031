export const MASTER_SERVER_ENDPOINTS = {
    serverBaseUrl: 'https://apitest.qhot.mx',
    basePath: 'qhot', // TODO: Change file in qhot-api server.ts l38 -> this line and that line
    areaOfInterest: {
        catalog: 'areaOfInterest',
    },
    register: {
        registerUserEndpoint: 'register/registerUser',
        verifyUserEndpoint: 'register/verifyUser',
        sendNewVerificationCode: 'register/sendNewVerificationCode',
        setAreasOfInterestEndpoint: 'register/areasOfInterest',
    },
    login: {
        auth: 'login/auth',
        authGoogle: 'login/authGoogle',
        authApple: 'login/authApple',
        recovery: 'login/forgotPassword',
    },
    support:{
        support: 'support/sendNewReport',
    },
    creator: {
        aditionalInfo:'creator/aditionalInfo',
        getAditionalInfo:'creator/aditionalInfo',
        search: 'creator/search',
        searchByCategory: 'creator/searchByCategory',
        popular: 'creator/popular',
        recent: 'creator/recent',
        posts: 'creator/posts',
        announcements: 'creator/announcements',
        videos: 'creator/videos',
        files: 'creator/files',
        lives: 'creator/lives',
        myFans: 'creator/fans',
        statistics: 'creator/statistics',
        payments: 'creator/payments',
        paidContentRates: 'creator/paidContentRates',
        paidContentRatesChange: 'creator/paidContentRateChangeRequest',
        blockedUser: 'creator/blockedUser',
        donationResponse: 'creator/donationResponse',
        uploadMediaCoverPicture: 'creator/coverPicture',
        getPricesCatalog: 'creator/pricesCatalog',
        metadataBecomeCreatorRequest: 'creator/metadataBecomeCreatorRequest',
        updateToken: 'creator/updateToken',
        fileMediaCount: 'creator/getCreatorPublicationCount',
        commissionPercentage: 'creator/commissionPercentage',
    },
    user: {
        basicProfile:'user/basicProfile',
        profile: 'user/profile',
        setPassword: 'user/password',
        notificationPreferences: 'user/notificationPreferences',
        subscriptions: 'user/subscription',
        helloRequest: 'creator/greeting',
        userHelloRequest: 'user/greeting',
        helloRequestResponse: 'user/paidContentRequestResponse',
        createHelloRequestResponse: 'creator/postPaidRequestResponse',
        becomeCreatorRequest: 'user/becomeCreatorRequest',
        rejectBecomeCreatorRequest: 'user/rejectBecomeCreatorRequest',
        becomeCreatorUploadMedia: 'user/becomeCreatorRequest/proofMedia',
        getRandomPhrase: 'user/becomeCreatorRequest/randomPhrase',
        areasOfInterestUser: 'user/areasOfInterestUser',
        getSocialMediaCatalog: 'user/socialMedia',
        setSocialMedia: 'user/socialMedia',
        createUserProfileMedia: 'user/profilePicture',
        deleteAccount: 'user/deleteAccount',
        getUserIsBlocked: 'user/getUserIsBlocked',
        getUserData: 'user/getUserData',
        saveUserData: 'user/saveUserData',
        updateOfficialId: 'user/saveUserData/updateOfficialId',
        reportFailure: 'user/reportApp'
    },
    publication: {
        feed: 'publication/feed',
        create: 'publication/',
        update: 'publication/',
        delete: 'publication/',
        media: 'publication/media',
        blurred: 'publication/media_blurred',
        comments: 'publication/comments',
        readPublication: 'publication',
        reactions: 'publication/reactions',
        createReaction: 'publication/reaction',
        deleteReaction: 'publication/reaction',
        comment: 'publication/comment',
        reportPublication: 'publication/report',
    },
    notifications: {
        getNotifications: 'notification',
        toggle: 'notification',
        toggleAll: 'notification/markAllAsRead',
        notificationType: 'notification/catalog',
        getNotificationCount: 'notification/getNotificationCount',
    },
    comments: {
        createComment: 'comment/comment',
        deleteComment: 'comment/comment',
        createReaction: 'comment/reaction',
        deleteReaction: 'comment/reaction',
        reportComment: 'comment/report',
        blockUserComments: 'comment/blocked',
        getCommentUserBlocked: 'comment/blocked',
    },
    payment: {
        setupIntent: 'payment/setupIntent',
        // setupIntentOxxo: 'payment/setupIntentOxxo',
        paymentIntent: 'payment/paymentIntent',
        paymentIos: 'payment/paymentIos',
        getPaymentMethods: 'payment/methods',
        setDefaultPaymentMethod: 'payment/defaultPaymentMethod', // Has path param /:paymentMethodId
        deletePaymentMethod: 'payment', // Has path param /:paymentMethodId
        getPaidContentPrice: 'payment/paidContentPrice',
        createSubscription: 'payment/subscription',
        cancelSubscription: 'payment/subscription',
        createSubscriptionIos: 'payment/subscriptionIos',
        updateExpiricySubscription: 'payment/updateExpiricySubscription',
        // createPaymentIntentOxxo: 'payment/createPaymentIntentOxxo',
        // updatePaymentIntentOxxo: 'payment/updatePaymentIntentOxxo',
        createIntentForMissingGreeting: 'payment/createIntentForMissingGreeting',
        createRefundIntentForMissingGreeting: 'payment/createRefundIntentForMissingGreeting'
    },
    mercadoPago: {
        setupIntent: 'mercadopago/setupIntent',
        saveCustomerCard: 'mercadopago/saveCustomerCard',
        // setupIntentOxxo: 'payment/setupIntentOxxo',
        createPayment: 'mercadopago/createPayment',
        createPreference: 'mercadopago/createPreference',
        paymentIntent: 'mercadopago/paymentIntent',
        paymentIos: 'mercadopago/paymentIos',
        getPaymentMethods: 'mercadopago/methods',
        setDefaultPaymentMethod: 'mercadopago/defaultPaymentMethod', // Has path param /:paymentMethodId
        deletePaymentMethod: 'mercadopago/deletePaymentMethod', // Has path param /:paymentMethodId
        getPaidContentPrice: 'mercadopago/paidContentPrice',
        getSubscriptionPlan: 'mercadopago/getSubscriptionPlan',
        createSubscription: 'mercadopago/subscription',
        cancelSubscription: 'mercadopago/subscription',
        createSubscriptionIos: 'mercadopago/subscriptionIos',
        updateExpiricySubscription: 'mercadopago/updateExpiricySubscription',
        createIntentForMissingGreeting: 'mercadopago/createIntentForMissingGreeting',
        createRefundIntentForMissingGreeting: 'mercadopago/createRefundIntentForMissingGreeting'
    },
    livestream: {
        getActiveLivestream: 'livestream/getActiveLivestream',
        getLivestreamById: 'livestream/getLivestreamById',
        updateLivestreamStatus: 'livestream/updateLivestreamStatus',
        createLivestreamHall: 'livestream/createLivestreamHall',
        createLivestreamHallFan: 'livestream/createLivestreamHallFan',
        endedLivestreamHall: 'livestream/endedLivestreamHall',
        getLivestreamInfo: 'livestream/getLivestreamInfo',
        getLivestreamInfoByCreatorId: 'livestream/getLivestreamInfoByCreatorId',
        updateLivestreamHallChatRoomId: 'livestream/updateLivestreamHallChatRoomId',
        createTokenChat: 'livestream/createTokenChat',
        getStatusLive: 'livestream/getStatusLive',
        getFansOnLivestream: 'livestream/getFansOnLivestream',
        updateLivestreamHallFanEndedAt: 'livestream/updateLivestreamHallFanEndedAt',
    },
    app: {
        version: 'info/getAppVersion',
    },
    collections:{
        allCollectionsByUser: 'collection/getAllCollectionsByUser',
        allCollectionsByPublicationId: 'collection//getCollectionsByPublicationId',
        createCollection: 'collection/createCollection',
        updateCollection: 'collection/updateCollection',
        deleteCollection: 'collection/deleteCollection',
        addToCollection: 'collection/addToCollection',
        removeFromCollection: 'collection/removeFromCollection',
        removeFromCollectionFeed: 'collection/removeFromCollectionFeed',
        getCollectionImages: 'collection',
        getCollectionVideos: 'collection',
    },
    stripe: {
        getTransferCapability: 'stripe/getTransferCapability',
        createAccountLink: 'stripe/createAccountLink',
    }
};

export const PAYMENT_SERVICE_CONFIG = {
    // eslint-disable-next-line max-len
    // Replace this value with the stripe key for the account (test or prod). It can be safely commited to Git
    publishableKey: 'pk_test_51Oj3fnGa6k8dGO8dNBfqSG8YI40b5Zfn8mt10ITmRDdY1L17B8wcGZYRCwcWjf6xOQqSdekjVdfBcbwBbNPgW4mh00cEuGvwzc',
};

export const MERCADO_PAGO_CONFIG = {
    publicKey: 'APP_USR-fa281945-f253-40d1-8f0f-d50c4d65467e',
  };

export const CRYPTOJS_SECRET = {
    secretKey: 'UWhvdCBlcyBsYSBtZXJhIG9uZGEgcGFyYSBzdWJpciBudWRlcw=='
}
